import React from 'react';
import { featureTiles } from './features';

class TileView extends React.PureComponent {
  featureGrid = () => (
    featureTiles.map((feature) => (
      <div
        key={feature.featureHeader}
        className="feature-tile"
      >
        <div className="icon">
          <img alt="logo" src={feature.icon} />
        </div>
        <div className="header">
          {feature.featureHeader}
        </div>
        <div className="desc">
          {feature.featureDesc}
        </div>
      </div>
    ))
  )

  render() {
    return (
      <section className="CliqFeatureGrid">
        <div className="divider" />
        <div className="grid-container">
          <div className="feature-grid">
            {this.featureGrid()}
          </div>
        </div>
        <div className="divider" />
      </section>
    );
  }
}

export default TileView;
