import React from 'react';
import PropTypes from 'prop-types';

import CliqHeroWeb from '../../images/cliq/hero/Cliq_Hero_Web.png';
import CliqHeroMobile from '../../images/cliq/hero/Cliq_Hero_Mobile.png';

export default function Hero({ windowWidth }) {
  return (
    <section className="CliqHero">
      <div
        className="content"
        data-rellax-speed="-2"
        data-rellax-percentage="0.5"
      >
        <div className="content-column left-column">
          <img
            className="cliq-hero-img"
            src={windowWidth > 1024 ? CliqHeroWeb : CliqHeroMobile}
            alt="Our premium oil has met its match"
          />
          {windowWidth > 1024 ? null : (
            <div className="hero-text-placeholder">
              <h2>Introducing Cliq from Select</h2>
              {/* eslint-disable-next-line max-len */}
              <p>The latest innovation from the Select family, Cliq is a sleek, pocket-sized, and intelligently designed vape delivery system. From the proprietary gravity-fed pod to its ultra-tough stainless steel encasing and premium hand feel, Cliq comfortably brings the best of high-quality oil and innovative hardware into the hands of cannabis consumers</p>
            </div>
          )}
          <div className="hero-text">
            <h2>Introducing Cliq from Select</h2>
            {/* eslint-disable-next-line max-len */}
            <p>The latest innovation from the Select family, Cliq is a sleek, pocket-sized, and intelligently designed vape delivery system. From the proprietary gravity-fed pod to its ultra-tough stainless steel encasing and premium hand feel, Cliq comfortably brings the best of high-quality oil and innovative hardware into the hands of cannabis consumers</p>
          </div>
        </div>
      </div>
    </section>
  );
}

Hero.propTypes = {
  windowWidth: PropTypes.number,
};
