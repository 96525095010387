import GravityFedIcon from '../../images/cliq/features/Icon-Contoured_gravity-fed_pod.svg';
import MagnetsIcon from '../../images/cliq/features/Icon-Heavy_duty_magnets.svg';
import VariableVoltageIcon from '../../images/cliq/features/Icon-Variable_voltage_settings.svg';
import AutoDrawIcon from '../../images/cliq/features/Icon-Auto_draw-activitation.svg';
import NaturalIcon from '../../images/cliq/features/Icon-Natural_elements.svg';
import SleekIcon from '../../images/cliq/features/Icon-Sleek_and_pocketable.svg';

import PodImg from '../../images/cliq/diagram/mobile/Cliq_PodFeatures_Layout_Pod_RGB.png';
import WickImg from '../../images/cliq/diagram/mobile/Cliq_PodFeatures_Layout_Wick_RGB.png';
import AutoImg from '../../images/cliq/diagram/mobile/Cliq_PodFeatures_Layout_AutoStart_RGB.png';
import UsbImg from '../../images/cliq/diagram/mobile/Cliq_PodFeatures_Layout_USBConnect_RGB.png';
import TripleImg from '../../images/cliq/diagram/mobile/Cliq_PodFeatures_Layout_TripleSeal_RGB.png';
import MagnetImg from '../../images/cliq/diagram/mobile/Cliq_PodFeatures_Layout_Magnet_RGB.png';
import VoltageImg from '../../images/cliq/diagram/mobile/Cliq_PodFeatures_Layout_VariableVoltage_RGB.png';
import CasingImg from '../../images/cliq/diagram/mobile/Cliq_PodFeatures_Layout_SteelCasing_RGB.png';

export const featureTiles = [
  {
    icon: GravityFedIcon,
    featureHeader: 'Contoured gravity-fed pod',
    featureDesc: 'Optimal preservation and ensures every last drop of premium Elite oil gets used',
  },
  {
    icon: MagnetsIcon,
    featureHeader: 'Heavy duty magnets',
    featureDesc: 'Strong magnetic pod + battery connection for sure fit and signature “click”',
  },
  {
    icon: VariableVoltageIcon,
    featureHeader: 'Variable voltage settings',
    featureDesc: 'High heat for more potency and vapor production. Low heat for more flavor experience',
  },
  {
    icon: AutoDrawIcon,
    featureHeader: 'Auto draw activation',
    featureDesc: '“Always on” auto draw battery for simple and easy operation',
  },
  {
    icon: NaturalIcon,
    featureHeader: 'Natural elements',
    featureDesc: 'Clay-based ceramic coil assembly wrapped in a natural cotton only wick',
  },
  {
    icon: SleekIcon,
    featureHeader: 'Sleek and Pocketable',
    featureDesc: 'Premium weighted hand feel and contoured edges for comfort and durability',
  },
];

// export default featureTiles;

export const diagramTiles = [
  {
    image: PodImg,
    imgAlt: 'Cliq pod',
    header: 'Every drop counts',
    desc: 'The unique, tapered reservoir gravity feeds every drop of oil into the coil. With Cliq, you always get what you paid for, right down to the last drop.',
  },
  {
    image: WickImg,
    imgAlt: 'Cliq wick',
    header: 'All natural',
    desc: 'The coil assembly features a wick made from natural cotton only, wrapped around a clay based ceramic coil. We don’t use any glass/silica fibre based materials in the coil assembly.',
  },
  {
    image: AutoImg,
    imgAlt: 'Cliq pod breath activation mechanism',
    header: 'Cruise control',
    desc: 'Unlike many other variable voltage batteries, Cliq retains the ease of simple breath activation. Just set the temperature you prefer and you’re good to go.',
  },
  {
    image: UsbImg,
    imgAlt: 'Cliq USB-C port',
    header: 'The right connections',
    desc: 'Cliq features a USB-C connector charging port to seamlessly fit right into your everyday routine.',
  },
  {
    image: TripleImg,
    imgAlt: 'Cliq pod seal',
    header: 'Triple Sealed',
    desc: 'We tested the product extensively, pushing it to its limits. From peak summer heat to below freezing winders, you can count on Cliq no matter the weather.',
  },
  {
    image: MagnetImg,
    imgAlt: 'Cliq pod magnet',
    header: 'Magnetic personality',
    desc: 'Tired of weak magnets? We were too, So we added heavy duty magnets for the strongest possible fit and most relable and signature “cliq” connection.',
  },
  {
    image: VoltageImg,
    imgAlt: 'Cliq device voltage mechanism',
    header: 'Variable voltage',
    desc: 'The Cliq features three voltage settings, putting temperature control at your fingertips. So wheather you like it hot and heavy or slow and low, Cliq always has a setting for you.',
  },
  {
    image: CasingImg,
    imgAlt: 'Cliq device case',
    header: 'Built to last',
    desc: 'Encased in an ultra-tough, extruded stainless steel shell and coated in a hard-wearing, matte-finish automotive grade paint for an extra premium hand feel.',
  },
];
