import React from 'react';
// todo: remove comments
import { navigate } from 'gatsby';
import Layout from '../components/Layout';
import Seo from '../components/SEO';
import NotFoundView from '../components/NotFoundView';
import CliqView from '../components/CliqView';

import '../styles/global.scss';

class ProductsPage extends React.PureComponent {
  render() {
    return (
      <Layout view="Cliq">
        <Seo title="Cliq" />
        <CliqView />
      </Layout>
    );
  }
}

export default ProductsPage;
