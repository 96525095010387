/* eslint-disable react/prop-types */
import React from 'react';

import Packaging from '../../images/cliq/options/Cliq-Product_3up.png';

export default function FeatureColumn({ data, windowWidth }) {
  return (
    <section className="CliqSplitColumn Options">
      <div>
        <div className="img-container">
          <img src={Packaging} alt="Cliq packaging" />
        </div>
        <div className="text-container">
          <h2>{data.title}</h2>
          <div className="content">{data.content}</div>
          <a className="cta" href={data.linkUrl}>
            {data.linkText}
          </a>
          <div className={windowWidth > 1024 ? 'desktopButton' : 'mobileButton'} />
        </div>
      </div>
    </section>
  );
}
