/* eslint-disable react/prop-types */
import React from 'react';

import OilDrip from '../../images/cliq/elite/Oil.png';
import CliqProduct from '../../images/cliq/elite/Elite_Oil_Device1.png';

export default function FeatureColumn({ data, windowWidth }) {
  return (
    <section className="CliqSplitColumn">
      <div className="elite-oil-container">
        <div className="text-container">
          <h2 className="elite-header">{data.title}</h2>
          <p className="elite-content">{data.content}</p>
          <a className="cta" href={data.linkUrl}>
            {data.linkText}
          </a>
          <div className={windowWidth > 1024 ? 'desktopButton' : 'mobileButton'} />
        </div>
        <div className="image-container">
          {windowWidth <= 767 ? (
            <img className="oil-drip" src={OilDrip} alt="Dripping oil" />
          ) : (
            <img src={CliqProduct} alt="Cliq device" className="elite-oil" />
          )}
        </div>
      </div>
    </section>
  );
}
