/* eslint-disable max-len */
import React from 'react';

import withMediaTypes from '../../types/withMediaTypes';
import withMedia from '../../hoc/withMedia';

import Hero from './hero';
import FeatureGrid from './featureGrid';
import FeatureDiagram from './featureDiagram';
import EliteOil from './eliteoil';
import Options from './options';

import ColorBanner from '../../images/cliq/Cliq_OilHero_AdobeStock308292542_RGB.png';

import './CliqView.scss';

class CliqView extends React.PureComponent {
  static propTypes = {
    ...withMediaTypes,
  };

  eliteOil = {
    title: 'Our premium Elite oil has met its match.',
    content: 'Cliq’s proprietary gravity-fed pods come filled with our famous Elite oil, our best selling oil on the market. Select Elite utilizes our highly refined distillation techniques to produce an activated, broad-spectrum oil with the highest THC levels available.',
    linkText: 'Find Select Elite oil',
    linkUrl: '',
  }

  productOptions = {
    title: 'Product Options',
    content: (<div>
      <p>Cliq comes in 3 product options:</p>
      <p><b>Cliq Kit:</b> includes Battery and one Elite Oil Pod—everything you need to get started.</p>
      <p><b>Cliq Variable Battery</b></p>
      <p><b>Cliq Pods:</b> Individual replacement pods available in sativa, hybrid, indica and in 0.5G and 1.0G sizes. A limited number of Elite strains will be available at launch.</p>
    </div>),
    linkText: 'Find Cliq by Select',
    linkUrl: '',
  }

  render() {
    const { windowWidth } = this.props;
    return (
      <>
        <article className="CliqPage">
          <Hero windowWidth={windowWidth} />
          <FeatureGrid />
          <FeatureDiagram windowWidth={windowWidth} />
          <EliteOil data={this.eliteOil} windowWidth={windowWidth} />
          <Options data={this.productOptions} windowWidth={windowWidth} />
          <img
            className="color-divider"
            src={ColorBanner}
            alt="Color Divider"
          />
        </article>
      </>
    );
  }
}

export default withMedia(CliqView);
