import React from 'react';
import PropTypes from 'prop-types';

import FlawlessBreakdown from '../../images/cliq/diagram/Flawless-Breakdown.png';
import FlawlessBreakdownPlaceholder from '../../images/cliq/diagram/Flawless-Breakdown-Placeholder.png';
import { diagramTiles } from './features';

export default function FeatureDiagram({ windowWidth }) {
    const data = {
        title: 'Flawless, inside and out.',
        content:
            'More than just a stylish piece of hardware, we stressed over even the smallest features and internal mechanics to make Cliq your customer’s new favorite way to vape.'
    };

    return (
        <section className='CliqDiagram'>
            <div>
                <div className="diagram-content-container">
                    <h2 className='diagram-header'>{data.title}</h2>
                    <p>{data.content}</p>
                </div>
                <div className='cliq-diagram-img-container'>
                    {windowWidth > 767 ? (
                        <img
                            src={FlawlessBreakdownPlaceholder}
                            alt='cliq-breakdown'
                            className='cliq-diagram-placeholder'
                        />
                    ) : null}
                    {windowWidth > 767 ? (
                        <img
                            src={FlawlessBreakdown}
                            alt='cliq-breakdown'
                            className='cliq-diagram-img'
                        />
                    ) : (
                        diagramTiles.map((tile) => (
                            <div key={tile.imgAlt} className='diagram-tile'>
                                <img src={tile.image} alt={tile.imgAlt} />
                                <h3>{tile.header}</h3>
                                <p>{tile.desc}</p>
                            </div>
                        ))
                    )}
                </div>
            </div>
        </section>
    );
}

FeatureDiagram.propTypes = {
    windowWidth: PropTypes.number
};
